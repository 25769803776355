<template>
  <div>
    <!-- <div v-if="!connectorsLoaded || !participantsLoaded" id="pageloader">
      <span class="title">Loading dashboard</span>
    </div> -->
    <template>
      <section class="hero is-bold zerow">
        <div class="hero-body py-2">
          <div class="container">
            <div class="level is-hidden-mobile">
              <div class="level-left pl-6">
                <figure class="image">
                  <img src="@/assets/ZeroW.png" />
                </figure>
              </div>
            </div>
            <div class="is-hidden-tablet has-text-centered">
              <figure class="mobile-fig image is-inline-block">
                <img src="@/assets/ZeroW.png">
              </figure>
            </div>
          </div>
        </div>
      </section>
      <div :class="{ container: tab !== 'home' }">
        <Home v-if="tab === 'admin'" :credentials="adminCredentials" @credentialChange="adminCredentialsChange" />
        <Login v-if="tab === 'login'" />
      </div>
      <footer class="footer">
        <div class="content has-text-centered has-text-white">
          <img src="@/assets/tno-logo.png" />
          <p>&copy; 2022 TNO - ZeroW</p>
        </div>
      </footer>
    </template>
  </div>
</template>

<script>
import Home from "./components/Home.vue";

import axios from "axios";
import "@fortawesome/fontawesome-free/js/all.js";
import Login from "./components/Login.vue";

export default {
  components: {
    Home,
    Login
  },
  data() {
    return {
      api: axios.create({
        baseURL: "/",
        timeout: 5000,
      }),
      tab: window.location.hash.replace("#", "") || "admin",
      adminCredentials: {
        username: "",
        password: "",
      },
    };
  },
  watch: {
    tab: function (newTab) {
      window.location.hash = newTab;
    },
  },
  created: async function () {
    if (
      localStorage.username !== undefined &&
      localStorage.password !== undefined
    ) {
      this.adminCredentials.username = localStorage.username;
      this.adminCredentials.password = localStorage.password;
    }
  },
  methods: {
    adminCredentialsChange: function (credentials) {
      localStorage.username = credentials.username;
      localStorage.password = credentials.password;
      this.adminCredentials = credentials;
    },
  },
  beforeDestroy: function () {
    clearInterval(this.connectorTimer);
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");

$tno-dark: #222;
$tno-blue: #72a7d1;

$family-sans-serif: "Open Sans", sans-serif;
$container-max-width: 1024px;
$text-strong: $tno-dark;
$tabs-link-color: $tno-blue;
$tabs-link-hover-color: darken($tno-blue, 10%);
$tabs-link-hover-border-bottom-color: darken($tno-blue, 10%);
$link: $tno-dark;
$dark: $tno-dark;
$card-shadow: 0 0.5em 1em -0.125em rgba($tno-blue, 0.15),
  0 0px 0 1px rgba($tno-blue, 0.05);
$card-header-shadow: 0 0.125em 0.25em rgba($tno-blue, 0.1);
$footer-background-color: black;
$footer-padding: 0rem 1.5rem 0rem;
$table-row-active-color: $tno-blue;
$info: $tno-blue;

@import "~bulma/bulma";
@import "~buefy/src/scss/buefy";

@media only screen and (min-width: 769px) and (max-width:1055px) {
  .pl-6 {
    padding-left: 0rem !important;
  }

  .hero-body {
    padding-left: 1.5rem !important;
  }
}

.zerow.hero {
  background-color: white !important;
}

tr.is-selected {
  background-color: $tno-blue !important;
  color: white !important;
}

.link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

html {
  background-color: black;
}

body {
  background-color: white;
}

.hero {
  &.is-bold {
    background-color: black;
  }

  .level img {
    max-height: 4rem;

    @include from($tablet) {
      max-height: 9rem;
    }
  }

  .mobile-fig img {
    max-height: 20vh;
    width: auto !important;
  }

  .title {
    color: white;
    font-size: 3rem;
    text-align: center;

    @include from($tablet) {
      text-align: left;
    }
  }
}

footer {
  position: relative;
  z-index: 20;

  >.content {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      padding-right: 3em;
      max-height: 8.5rem;

      @include from($desktop) {
        padding-right: 5em;
      }
    }
  }
}

.card-columns {
  columns: auto 1;
  margin-bottom: 3em;

  @include from($desktop) {
    columns: auto 2;
  }

  @include from($fullhd) {
    columns: auto 3;
  }

  .card-column {
    break-inside: avoid;
    margin-bottom: 1em;

    figure {
      display: flex;
      justify-content: center;
      align-items: center;

      &.is-2by1>div>div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        >img {
          max-width: 100%;
          width: auto !important;
          height: auto !important;
          max-height: 100%;
          position: relative;
          top: auto;
          left: auto;
          margin-top: 0;
        }
      }
    }
  }
}

span.tag {
  margin: 0.2em;
}

#pageloader {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  position: fixed;
  padding-top: 2em;
  background: hsl(217, 71%, 53%);
  z-index: 999998;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  will-change: transform;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    border-radius: 100%;
    content: "";
    z-index: 9999;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 0 solid #fff;
    -webkit-animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 2em 0 0 0;
    font-size: 0.875em;
    letter-spacing: 0.1em;
    line-height: 1.5em;
    color: #fff;
    white-space: nowrap;
  }
}

@-webkit-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}
</style>