<template>
    <section class="section">
        <b-modal v-model="irmaModalActive" :width="400">
            <div id="irma-web-form"></div>
        </b-modal>
    </section>
</template>

<style scoped>
.irma-web-form {
    width: 100% !important;
}
</style>
<script>
import axios from "axios";
const irma = require('@privacybydesign/irma-frontend');
export default {
    created() {
        var urlParams = new URLSearchParams(window.location.search);
        console.log(urlParams.get('email'))
        this.urlParams = urlParams
        this.createApi();
        this.startUpIrma();
    },
    data() {
        return {
            api: null,
            irmaModalActive: false
        }
    },
    methods: {
        createApi: async function () {
            console.log("creating api")
            this.api = axios.create({
                "baseURL": "api/",
                "timeout": 5000
            });
        },
        startUpIrma: async function () {
            var email = atob(this.urlParams.get('email'));
            if (!email) {
                console.log('No Email found');
            }
            this.irmaModalActive = true;
            await new Promise(r => setTimeout(r, 100));
            const irmaWeb = irma.newWeb({
                debugging: false,            // Enable to get helpful output in the browser console
                element: '#irma-web-form', // Which DOM element to render to
                language: 'en',

                // Back-end options
                session: {
                    // Point this to your IRMA server:
                    url: 'api',

                    start: {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            'email': email,
                        }),
                        url: o => `${o.url}/oauth/signin`,

                    },
                    // Define how request results should be mapped to a session pointer to display
                    mapping: {
                        // The only thing included in the request is the session pointer, so disable additional parsing.
                        sessionPtr: r => r,
                    },

                    // Results are handled on backend, so not needed here
                    result: false,
                }
            });

            try {
                const result = await irmaWeb.start()
                console.log("Successful disclosure! 🎉", result);
                window.location.href = atob(this.urlParams.get('url'))
            } catch (e) {
                console.error("Couldn't do what you asked 😢", e)
            }
            this.irmaModalActive = false;
        },
        beforeDestroy: function () {
            clearInterval(this.resultTimer);
        },
    }
}
</script>
