<template>
  <section class="section">
    <h2 class="title">Enter the dataspace</h2>
    <em> This demo is meant as an introduction to dataspaces for the ZeroW Project. To demonstrate the use of dataspaces
      a Marketplace instance can be instantiated for your "business". In this marketplace, each "business" has its own
      instance, to ensure
      you are in control of your own data. This form is used to instantiate your own instance of the marketplace. Before
      you enter the dataspace, we verify you via <a class="link" href="https://irma.app/">IRMA</a>. To be ready to enter
      the dataspace please follow the following steps:
    </em>
    <ol class="mt-5 ml-6">
      <li>Download and install the IRMA app on your smartphone.</li>
      <li>Add a card with your e-mailaddress in the IRMA app or click <a class="link">here</a> to do it via the browser.
      </li>
      <li>Enter a name for your "business" in the field below</li>
      <li>Scan the IRMA QR code that pops up and allow us to verify you with your e-mailaddress in the IRMA app.</li>
    </ol>
    <h4 class="title mt-5">
      Enter the name for your "business" here
    </h4>
    <form @submit.prevent="startUpIrma" ref="validateInput">
      <b-field horizontal label="Business name">
        <b-input v-model="message" required
          validation-message="Please enter at least 3 characters, only lowercase, uppercase, numbers, spaces and hyphens are allowed"
          minlength="3" pattern="[A-Za-z0-9- ]*" placeholder="Business name" />
      </b-field>
      <b-field horizontal>
        <b-button :disabled=started class="p-2" label="Start" type="is-info" @click="startUpIrma" />
      </b-field>
    </form>
    <p class="mt-5" v-if="this.started && !this.finished">
      The services for your marketplace are being created, as soon as your app is ready, the button below will become
      available.
    </p>

    <p class="mt-5" v-if="this.started && this.finished">
      Your marketplace is ready. Click below to visit your marketplace.
    </p>
    <b-button tag="a" :loading=!finished v-if="started" type="is-info" label="Go to your marketplace"
      :href="this.marketplaceUrl" target="_blank" />

    <p class="my-5"><em class="has-text-grey-light">
        Specifically, we use your e-mailaddress to verify you.
        The e-mailaddress is used because IRMA is focused on the Netherlands, other attributes may not be available
        throughout Europe. We use the e-mailaddress to verify you only, afterwards the e-mailaddress is not saved.
      </em>
    </p>
    <b-modal v-model="irmaModalActive" :width="400">
      <div id="irma-web-form"></div>
    </b-modal>
  </section>
</template>

<style scoped>
.irma-web-form {
  width: 100% !important;
}
</style>
<script>
import axios from "axios";
const irma = require('@privacybydesign/irma-frontend');
export default {
  created() {
    this.createApi();
  },
  data() {
    return {
      api: null,
      irmaModalActive: false,
      message: null,
      started: null,
      finished: null,
      marketplaceUrl: null
    }
  },
  methods: {
    checkAvailability: async function () {
      try {
        await this.api.post('checkavailability', {
          appName: this.message
        }, {
          headers: {
            "content-type": "application/json"
          }
        })
        return true;
      } catch (error) {
        if (error.response) {
          console.log(error.response);
          if (error.response.status === 400) {
            this.$buefy.toast.open({
              message: error.response.data,
              duration: 10000,
              type: 'is-danger'
            });
          }
          return false;
        }
      }

    },
    createApi: async function () {
      this.api = axios.create({
        "baseURL": "api/",
        "timeout": 5000
      });
    },
    keepPolling: async function (urlname) {
      const sleep = ms => new Promise(res => setTimeout(res, ms))
      for (var i = 0; i < 60; i++) {
        try {
          let resp = await axios.get(`https://marketplace.zerow.dataspac.es/${urlname}/`)
          if (resp.status === 200) {
            this.marketplaceUrl = `https://marketplace.zerow.dataspac.es/${urlname}/`
            this.finished = true
            return;
          }
        }
        catch (e) {
          await sleep(500);
        }
      }
    },
    createApp: async function () {
      const resp = await this.api.post("createapp", {
        appName: this.message
      }, {
        headers: {
          "content-type": "application/json"
        }
      })
      this.keepPolling(resp.data)
    },
    startUpIrma: async function () {
      if (!this.$refs.validateInput.checkValidity()) {
        this.$buefy.toast.open({
          message: 'Please fill in all required fields',
          duration: 10000,
          type: 'is-warning'
        });
        return;
      }
      let available = await this.checkAvailability();
      if (!available) {
        return;
      }

      this.irmaModalActive = true;
      await new Promise(r => setTimeout(r, 100));
      const irmaWeb = irma.newWeb({
        debugging: false,            // Enable to get helpful output in the browser console
        element: '#irma-web-form', // Which DOM element to render to
        language: 'en',

        // Back-end options
        session: {
          // Point this to your IRMA server:
          url: 'api',

          start: {
            method: 'GET',
            url: o => `${o.url}/startAuth`,
          },

          // Define how request results should be mapped to a session pointer to display
          mapping: {
            // The only thing included in the request is the session pointer, so disable additional parsing.
            sessionPtr: r => r,
          },

          // Results are handled on backend, so not needed here
          result: false,
        }
      });

      try {
        const result = await irmaWeb.start()
        console.log("Successful disclosure! 🎉", result);
      } catch (e) {
        console.error("Couldn't do what you asked 😢", e)
      }
      this.irmaModalActive = false;
      this.started = true;

      try {
        // If the domain is in a hardcoded list (backend side), continue.
        await this.api.get("check-domain")
      } catch (error) {
        if (error.response) {
          console.log(error.response);
          if (error.response.status === 400) {
            this.$buefy.toast.open({
              message: error.response.data,
              duration: 10000,
              type: 'is-danger'
            });
            this.started = false;
            // The backend will return 400 if trying to access with an emailadress that has a domain outside of the list of project partners.
            return;
          }
        }
      }
      await this.createApp()
    },
    beforeDestroy: function () {
      clearInterval(this.resultTimer);
    },
  }
}
</script>
